import { generateUUID, post } from '../utils';

const url = `${process.env.APP_URL}/event/pageview`;

/**
 * Class PageView.
 */
export default class PageView {
	uuid = '';

	/**
	 * Constructor.
	 */
	constructor() {
		this.uuid = generateUUID();
	}

	/**
	 * Return UUID.
	 *
	 * @returns {string}
	 */
	getUUID(): string {
		return this.uuid;
	}

	/**
	 * Return URL parameters.
	 *
	 * @returns {*}
	 */
	getParameters() {
		return {
			uuid: this.getUUID(),
			page: 0,
		};
	}

	create(params: any) {
		return post(url, params);
	}
}
