import { Content, Site, Visit, Visitor } from './components';
import { ContentData, defaults as contentDefaults } from './components/Content';
import { SiteData, defaults as siteDefaults } from './components/Site';
import { VisitorData, defaults as visitorDefaults } from './components/Visitor';
import { PageView, Listener } from './events';

type AllParameters = {
	content: any;
	visitor: any;
	visit: any;
	site: any;
	page_view: any;
};

type AllOptions = {
	content: ContentData;
	site: SiteData;
	visitor?: VisitorData;
};

const defaults: AllOptions = {
	content: contentDefaults,
	site: siteDefaults,
	visitor: visitorDefaults,
};

let listening = false;

/**
 * Class Events.
 */
export default class Events {
	options: AllOptions = { ...defaults };

	content: Content;
	site: Site;
	visit: Visit;
	visitor: Visitor;
	page_view: PageView;

	/**
	 * Constructor.
	 *
	 * @param options
	 */
	constructor(options = defaults) {
		this.options = { ...defaults, ...options };

		this.content = new Content(options.content);
		this.site = new Site(options.site);
		this.visit = new Visit();
		this.visitor = new Visitor(options.visitor);
		this.page_view = new PageView();
	}

	/**
	 * Return URL Parameters.
	 *
	 * @returns {{site: *, visit: *, visitor: {uuid: *}, content: {}}}
	 */
	getParameters(): AllParameters {
		const params = {
			content: this.content.getParameters(),
			visitor: this.visitor.getParameters(),
			visit: this.visit.getParameters(),
			site: this.site.getParameters(),
			page_view: this.page_view.getParameters(),
		};

		// Move "page" from content to page_view.
		if (params.content.page) {
			params.page_view.page = parseInt(`${params.content.page}`, 10);
			delete params.content.page;
		}

		return params;
	}

	announcePageViewUUID(uuid: string) {
		document.dispatchEvent(
			new CustomEvent('intentPathSetPageViewUUID', { detail: { uuid } })
		);
	}

	/**
	 * Create a PageView.
	 */
	pageview() {
		this.announcePageViewUUID(this.page_view.getParameters().uuid);
		return this.page_view.create(this.getParameters());
	}

	/**
	 * Listen for Click Events.
	 *
	 * @param options
	 */
	listen = (options: AllOptions) => {
		if (!listening) {
			listening = true;
			new Listener(this.getParameters(), options).start();
		}
	};
}

document.dispatchEvent(new CustomEvent('intentPathLoaded', { detail: Events }));
