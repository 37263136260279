function random() {
	return window !== undefined &&
		window.crypto !== undefined &&
		window.crypto.getRandomValues !== undefined
		? window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295
		: Math.random();
}

const randomHex = (digit: string) => {
	const intDigit = parseInt(digit, 10);

	// A random number from 0 to 15, unless intDigit is 8, in which case
	// a random number from 8 to 11.
	return (intDigit ^ ((random() * 16) >> (intDigit / 4))).toString(16);
};

/**
 * Returns a valid V4 UUID.
 *
 * @returns {string}
 */
function setUUID() {
	const template = '10000000-1000-4000-8000-100000000000';

	return template.replace(
		/[018]/g, // Replace zeroes, ones, and eights with
		randomHex // random hex digits
	);
}

/**
 * Returns a V4 UUID in the xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx format.
 *
 * @returns string
 */
export function generateUUID() {
	return setUUID().replace(/-/g, '');
}

export default generateUUID;
