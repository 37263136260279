export interface SiteData {
	id: number;
}

export const defaults: SiteData = {
	id: 0,
};

/**
 * Class Site.
 */
export default class Site {
	options: SiteData = { ...defaults };

	/**
	 * Constructor.
	 *
	 * @param options
	 */
	constructor(options: SiteData = defaults) {
		this.options = { ...defaults, ...options };
	}

	/**
	 * Return URL Parameters.
	 *
	 * @returns {*}
	 */
	getParameters() {
		return this.options;
	}
}
