import { FlatObjectStringMap, ObjectStringNull } from '../interfaces';

export function splitAtFirst(character: string, str: string) {
	const split = str.split(character);
	return [split[0] || '', split.slice(1).join(character) || ''];
}

const URLParams = [
	'utm_source',
	'utm_campaign',
	'utm_medium',
	'utm_term',
	'utm_content',
	'gclid',
	'fbclid',
	'msclkid',
	'acquisition_source',
	'acquisition_id',
];

/**
 * Parse params from a url.
 */
export function parse(link: string) {
	const params = splitAtFirst('?', link)[1].split('&');

	const out: FlatObjectStringMap = {};

	return (
		params.reduce((object, paramPair) => {
			const [param, value] = splitAtFirst('=', paramPair);

			if (URLParams.includes(param)) {
				object[param] = decodeURIComponent(value);
			}

			return object;
		}, out) || {}
	);
}

/**
 * Serialize an Object into a query string.
 */
export function serialize(
	object: ObjectStringNull | null,
	prefix: string = ''
): string {
	let str = [],
		k,
		v;

	for (const p in object) {
		if (!object.hasOwnProperty(p)) {
			continue;
		}

		if (~p.indexOf('[')) {
			k = prefix
				? `${prefix}][${p.substring(0, p.indexOf('['))}]${p.substring(
						p.indexOf('[')
				  )}`
				: p;
		} else {
			k = prefix ? `${prefix}[${p}]` : p;
		}

		v = object[p];

		str.push(
			typeof v == 'object'
				? serialize(v, k)
				: `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
		);
	}
	return str.join('&');
}
