import Promise from 'promise-polyfill';

const goodStatuses = [200, 201, 202];

/**
 * Make XHR POST Request.
 *
 * @param data
 * @private
 */
export function post(url: string, data: any) {
	const xhr = new XMLHttpRequest();

	return new Promise((resolve, reject) => {
		xhr.open('POST', url, true);
		xhr.setRequestHeader('Content-type', 'application/json');
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.onreadystatechange = () => {
			const { readyState, status, responseText } = xhr;

			if (readyState !== 4) {
				return;
			}

			const response = JSON.parse(responseText);

			if (goodStatuses.includes(status)) {
				resolve(response);
			} else {
				console.log(response, status);
				reject(response);
			}
		};

		xhr.send(JSON.stringify(data));
	});
}

export default post;
