export interface ContentData {
	page?: number;
	title?: string;
	path?: string;
	internal_id?: string;
	category?: string;
	type?: string;
}

export const defaults: ContentData = {};

/**
 * Class Content.
 */
export default class Content {
	options = { ...defaults };

	constructor(options: ContentData) {
		this.options = { ...defaults, ...options };
	}

	/**
	 * Return URL Parameters.
	 *
	 * @returns {{}}
	 */
	getParameters() {
		return this.options;
	}
}
