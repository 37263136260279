import { QueryString, generateUUID, setCookie, getCookie } from '../utils';
import { CookieConfig } from '../utils/Cookie';

/**
 * Class Visit.
 */
export default class Visit {
	cookie: CookieConfig = {
		key: 'visit_uuid',
		expiration: 24 * 3600,
	};

	options = {};

	/**
	 * Constructor.
	 *
	 * @param options
	 */
	constructor(options = {}) {
		this.options = options;

		if (!this.getUUID()) {
			this.setUUID();
		}
	}

	/**
	 * Returns UUID key.
	 *
	 * @returns {string|string|*}
	 */
	getCookieKey = () => {
		return this.cookie.key;
	};

	/**
	 * Get Cookie expiration.
	 *
	 * @returns {number}
	 */
	getCookieExpiration = () => {
		return this.cookie.expiration;
	};

	/**
	 * Return UUID from storage.
	 *
	 * @returns {*}
	 */
	getUUID = () => {
		return getCookie(this.getCookieKey());
	};

	/**
	 * Store UUID in storage.
	 *
	 * @returns {*}
	 */
	setUUID = () => {
		return setCookie(
			this.getCookieKey(),
			generateUUID(),
			this.getCookieExpiration()
		);
	};

	/**
	 * Return URL parameters.
	 *
	 * @returns {*}
	 */
	getParameters = () => {
		return {
			uuid: this.getUUID(),
			...QueryString.parse(window.location.search),
		};
	};
}
