import cookie from 'cookie';

export type CookieConfig = { expiration: number; key: string };

export const get = (key: string) => {
	let cookies = cookie.parse(document.cookie);

	return cookies[key];
};

export const set = (key: string, value: string, expiration: number) => {
	document.cookie = cookie.serialize(key, value, {
		path: '/',
		maxAge: expiration,
	});

	return value;
};
