import { QueryString } from '../utils';

type ListenerOptions = {
	selector: string;
	siteId: number;
};

type ListenerParams = {
	content: object;
};

type KeyValueStore = { [key: string]: string };

type MetaData = {
	events: object;
	content: object;
};

type MonitoredEventName = 'contextmenu' | 'mousedown' | 'touchstart';

const defaults: ListenerOptions = {
	selector: '[data-event]',
	siteId: 0,
};

let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
    }
  });
  window.addEventListener("click", null as any, opts);
  window.removeEventListener("click", null as any, opts);
} catch (e) {}
const listenerOptions = supportsPassive ? { passive: true } as const : false;

/**
 * Class Listen.
 */
export default class Listener {
	options: ListenerOptions = { ...defaults };
	clickEvents = ['contextmenu', 'mousedown', 'touchstart'] as const;
	url = '';
	params: ListenerParams = { content: {} };

	constructor(params: ListenerParams, options: object) {
		this.options = { ...defaults, ...options };
		this.params = params;
		this.url = `${process.env.APP_URL}/event/redirect` || '';
	}

	/**
	 * Start listener.
	 */
	start = async () => {
		this.clickEvents.forEach(event => {
			this._documentOn(event);
		});
	};

	/**
	 * Format Parameter key.
	 */
	_formatParamKey = (attribute: string, key: RegExp) => {
		return attribute.replace(key, '').replace(/-/g, '_');
	};

	/**
	 * Get Meta data.
	 *
	 * @private
	 */
	_mapMeta = (element: Element): MetaData => {
		const eventRegex = /^data-event-/,
			contentRegex = /^data-event-content-/;

		let events: KeyValueStore = {},
			content: KeyValueStore = {};

		Array.from(element.attributes).forEach(attribute => {
			if (contentRegex.test(attribute.name)) {
				content[this._formatParamKey(attribute.name, contentRegex)] =
					attribute.value;
			} else if (eventRegex.test(attribute.name)) {
				events[this._formatParamKey(attribute.name, eventRegex)] =
					attribute.value;
			}
		});

		return {
			events,
			content,
		};
	};

	/**
	 * Create a redirect URL for given dom element.
	 *
	 * @private
	 */
	_createRedirectURL = (element: Element) => {
		const metaData = this._mapMeta(element);

		const event = {
			external_url: element.getAttribute('href'),
			...metaData.events,
		};

		const content = { ...this.params.content, ...metaData.content };
		const params = QueryString.serialize({ ...this.params, event, content });

		return `${this.url}?${params}`;
	};

	/**
	 * Create event listeners on the document level.
	 *
	 * @param event
	 * @private
	 */
	_documentOn = (event: MonitoredEventName) => {
		document.addEventListener(event, (e: MouseEvent | TouchEvent) => {
			if (!e.target) {
				return;
			}

			if (!(e.target as HTMLElement).closest) {
				return;
			}

			const element = e.target as HTMLElement;
			const anchor = element.closest('a');

			if (anchor && anchor.hasAttribute('data-event')) {
				this._handleEventListener(anchor);
			}
		}, listenerOptions);
	};

	/**
	 * Handles the event redirect.
	 *
	 * @param element
	 * @private
	 */
	_handleEventListener = (element: Element) => {
		const changed = element.hasAttribute('data-original-url');

		if (!changed) {
			const redirect = this._createRedirectURL(element);
			element.setAttribute(
				'data-original-url',
				element.getAttribute('href') || ''
			);
			element.setAttribute('href', redirect);
		}
	};
}
