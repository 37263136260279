import { generateUUID } from '../utils/UUID';
import { setCookie, getCookie } from '../utils';
import { CookieConfig } from '../utils/Cookie';

export interface VisitorData {
	first_name?: string;
	last_name?: string;
	email?: string;
	postal_code?: string;
	country?: string;
	state?: string;
	city?: string;
}

export const defaults = {};

/**
 * Class Visitor.
 */
export default class Visitor {
	cookie: CookieConfig = {
		key: 'visitor_uuid',
		expiration: 630720000, // 20 years.
	};

	options: VisitorData = { ...defaults };

	constructor(options: VisitorData = defaults) {
		this.options = { ...defaults, ...options };

		if (!this.getUUID()) {
			this.setUUID();
		}
	}

	/**
	 * Returns UUID key.
	 *
	 * @returns {*}
	 */
	getCookieKey = () => {
		return this.cookie.key;
	};

	/**
	 * Get Cookie expiration.
	 *
	 * @returns {number}
	 */
	getCookieExpiration = () => {
		return this.cookie.expiration;
	};

	/**
	 * Return UUID from storage.
	 *
	 * @returns {*}
	 */
	getUUID = () => {
		return getCookie(this.getCookieKey());
	};

	/**
	 * Store UUID in storage.
	 */
	setUUID = () => {
		return setCookie(
			this.getCookieKey(),
			generateUUID(),
			this.getCookieExpiration()
		);
	};

	/**
	 * Return URL parameters.
	 *
	 * @returns {{uuid: *}}
	 */
	getParameters() {
		return {
			uuid: this.getUUID(),
			...this.options,
		};
	}
}
